import Editor, { Monaco, OnMount } from "@monaco-editor/react";
import * as React from "react";
import { MutableRefObject, useEffect } from "react";
import { useDebounce } from "../hooks/use-debounce";
import { RecipeObject, ScriptParameter } from "../storage/declare";
import { removeRecipeDeclarationCache } from "./declaration";
import { editorResolveRecipesModifier } from "./modifiers/resolve-recipes";
import { mountEditorConfigs, mountEditorModules, mountGlobalVariables, mountRecipes } from "./monaco";
import { RecipeCacheManager } from "./recipe-cache/recipe-cache-manager";

export type EditorViewProps = {

    readonly editorRef: MutableRefObject<any | null>;
    readonly monacoRef: MutableRefObject<Monaco | null>;

    readonly currentRecipeIdentifier?: string;

    readonly code: string;
    readonly setCode: (newCode: string) => void;

    readonly parameters?: ScriptParameter[];
};

export const EditorView: React.FC<EditorViewProps> = (props: EditorViewProps) => {

    const recipesToBeMounted: RecipeObject[] = RecipeCacheManager
        .getInstance()
        .getCachedRecipes()
        .filter((item: RecipeObject) => {
            return item.identifier !== props.currentRecipeIdentifier;
        });

    const handleEditorDidMount: OnMount = (editor: any, monaco: Monaco) => {

        props.editorRef.current = editor;
        props.monacoRef.current = monaco;

        mountEditorConfigs(monaco);
        mountEditorModules(monaco);
        mountRecipes(monaco, recipesToBeMounted);
        mountGlobalVariables(monaco, props.parameters ?? []);
    };

    const debouncedValue = useDebounce(props.code, 1000);

    useEffect(() => {

        if (props.monacoRef.current === null) {
            return;
        }
        mountRecipes(props.monacoRef.current, recipesToBeMounted);
        if (typeof props.currentRecipeIdentifier === 'string') {
            removeRecipeDeclarationCache(props.currentRecipeIdentifier);
        }
    }, [recipesToBeMounted.length])

    useEffect(() => {

        if (props.monacoRef.current === null
            || props.editorRef.current === null) {
            return;
        }

        mountGlobalVariables(props.monacoRef.current, props.parameters ?? []);
    }, [props.parameters?.length]);

    const currentDecorations = React.useRef<string[]>([]);

    useEffect(() => {

        (async () => {

            if (props.monacoRef.current === null
                || props.editorRef.current === null) {
                return;
            }

            currentDecorations.current = await editorResolveRecipesModifier(
                props.code,
                currentDecorations.current ?? [],
                props.editorRef.current,
                props.monacoRef.current,
            );
        })();
    }, [debouncedValue, props.monacoRef.current, props.editorRef.current]);

    useEffect(() => {

        return () => {

            props.editorRef.current = null;
            props.monacoRef.current = null;
        }
    }, []);

    return <Editor
        defaultLanguage="javascript"
        options={{
            wordWrap: "on",
            minimap: {
                renderCharacters: false,
            },
        }}
        value={props.code}
        onChange={(newCode: string | undefined) => props.setCode(newCode ?? "")}
        onMount={handleEditorDidMount}
    />;
};
