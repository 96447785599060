import Axios from "axios";
import { RecipeObject } from "../storage/declare";
import { API_GATEWAY_DOMAIN } from "./definition";
import { buildRequestHeaders } from "./util";

export type CallRecipeGetInput = {
    readonly identifier: string;
} | {
    readonly owner: string;
    readonly title: string;
};

export const callRecipeGet = async (input: CallRecipeGetInput): Promise<RecipeObject> => {

    const url = `https://${API_GATEWAY_DOMAIN}/recipe`;

    const requestHeaders = await buildRequestHeaders();

    const response = await Axios.get(url, {
        params: input,
        headers: requestHeaders,
    });

    const responseRecipe = response.data.recipe;

    return {
        identifier: responseRecipe.identifier,
        name: responseRecipe.title,
        owner: responseRecipe.owner,
        script: responseRecipe.script,
        declaration: responseRecipe.declaration,
    };
};
