import { Box, Button, Header, Link, Table } from "@cloudscape-design/components";
import * as React from "react";
import { ResultListBlockElement } from "../declare";

export type ListResultSectionViewProps = {

    readonly elements: ResultListBlockElement[];
};

export const ListResultSectionView: React.FC<ListResultSectionViewProps> = (props: ListResultSectionViewProps) => {

    return <Table
        header={
            <Header variant="h3">
                List
            </Header>
        }
        columnDefinitions={[
            {
                id: "Name",
                header: "Name",
                cell: (item: ResultListBlockElement) => item.text || "-",
            },
            {
                id: "Action",
                header: "Actions",
                cell: (item: ResultListBlockElement) => (
                    <Link href={item.href} external={true}>Visit</Link>
                ),
            },
        ]}
        items={props.elements}
        loadingText="Loading resources"
        empty={
            <Box textAlign="center" color="inherit">
                <b>No resources</b>
                <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                >
                    No resources to display.
                </Box>
                <Button>Create resource</Button>
            </Box>
        }
    />
};
