import { Button, Modal, SpaceBetween, StatusIndicator } from "@cloudscape-design/components";
import * as React from "react";
import { ResultBlock } from "./declare";
import { ResultSectionView } from "./section";

export type ResultViewProps = {

    readonly visible: boolean;
    readonly onClose: () => void;

    readonly resultBlocks: ResultBlock[];
    readonly completed: boolean;
    readonly succeed: boolean;
};

export const ResultView: React.FC<ResultViewProps> = (props: ResultViewProps) => {

    return <Modal
        size="large"
        visible={props.visible}
        onDismiss={props.onClose}
        header="Execution Result"
        footer={<div style={{
            display: 'flex',
        }}>
            {props.completed
                ? props.succeed
                    ? <StatusIndicator type="success">
                        Success
                    </StatusIndicator>
                    : <StatusIndicator type="error">
                        Failed
                    </StatusIndicator>
                : <StatusIndicator type="loading">
                    In Progress
                </StatusIndicator>}
            <div style={{
                flex: 1,
            }}></div>
            <Button
                variant="primary"
                onClick={props.onClose}
            >Close</Button>
        </div>}
    >
        <SpaceBetween size="m">
            {props.resultBlocks.map((block: ResultBlock, index: number) => {
                return <ResultSectionView
                    key={index}
                    block={block}
                />
            })}
        </SpaceBetween>
    </Modal>
};
