import Axios from "axios";
import { ScriptObject, ScriptParameter } from "../storage/declare";
import { API_GATEWAY_DOMAIN } from "./definition";
import { buildRequestHeaders } from "./util";

export type CallScriptPutInput = {

    readonly identifier?: string;
    readonly title: string;
    readonly script: string;
    readonly parameters: ScriptParameter[];
};

export type CallScriptPutOutput = {

    readonly identifier: string;
    readonly replaced: boolean;
};

export const callScriptPut = async (
    input: CallScriptPutInput,
): Promise<CallScriptPutOutput> => {

    const url = `https://${API_GATEWAY_DOMAIN}/script`;

    const requestHeaders = await buildRequestHeaders();

    const response = await Axios.put(url, {
        ...input,
        parameters: JSON.stringify(input.parameters),
    }, {
        headers: requestHeaders,
    });

    return response.data;
};
