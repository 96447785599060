import { Input } from "@cloudscape-design/components";
import produce from "immer";
import * as React from "react";
import { ScriptObject, ScriptParameter } from "../../storage/declare";

export type ToolParametersStringViewProps = {

    readonly index: number;
    readonly parameter: ScriptParameter;
    readonly targetScript: ScriptObject;
    readonly setTargetScript: (next: ScriptObject) => void;
};

export const ToolParametersStringView: React.FC<ToolParametersStringViewProps> = (props: ToolParametersStringViewProps) => {

    return <Input
        value={props.parameter.value}
        onChange={(event) => {
            const newTargetScript = produce(props.targetScript, (draft) => {
                draft.parameters[props.index].value = event.detail.value;
            });
            props.setTargetScript(newTargetScript);
        }}
    />;
};
