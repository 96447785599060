import { ExpandableSection } from "@cloudscape-design/components";
import { MarkedDebugSnapshotScope } from "@sudoo/marked";
import * as React from "react";

export type DebugResultSectionScopeViewProps = {

    readonly scope: MarkedDebugSnapshotScope;
    readonly level?: number;
};

export const DebugResultSectionScopeView: React.FC<DebugResultSectionScopeViewProps> = (props: DebugResultSectionScopeViewProps) => {

    const level: number = props.level ?? 0;
    const scopeKeyValue = props.scope.getKeyValueObject();

    return <div style={{
        paddingLeft: `${level * 16}px`,
    }}>
        {Object.keys(scopeKeyValue).map((key: string, index: number) => {
            return <div key={index}>
                {[
                    key,
                    JSON.stringify(scopeKeyValue[key]),
                ].join(": ")}
            </div>
        })}
        {props.scope.hasParent() ? <ExpandableSection
            variant="navigation"
            header="Parent Scope"
        >
            <DebugResultSectionScopeView
                scope={props.scope.getParent() as MarkedDebugSnapshotScope}
                level={level + 1}
            />
        </ExpandableSection> : null}
    </div>
};
