import { ButtonDropdown, Container, FormField, Input, SpaceBetween } from "@cloudscape-design/components";
import { produce } from "immer";
import * as React from "react";
import { ScriptObject } from "../storage/declare";
import { ToolParametersInputView } from "./tool-parameters/parameter-input";

export type ToolParametersViewProps = {

    readonly targetScript: ScriptObject;
    readonly setTargetScript: (next: ScriptObject) => void;
};

export const ToolParametersView: React.FC<ToolParametersViewProps> = (props: ToolParametersViewProps) => {

    return <SpaceBetween size="m" direction="horizontal">
        {props.targetScript.parameters.map((parameter, index: number) => {
            return <Container key={index}>
                <FormField
                    label={<div style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                    }}>
                        <div style={{
                            flex: 1,
                        }}>Key</div>
                        <ButtonDropdown
                            variant="icon"
                            items={[
                                {
                                    text: "Parameter Type",
                                    id: "parameter-type",
                                    disabled: false,
                                    items: [
                                        { id: "string", text: "String" },
                                        { id: "file", text: "File" },
                                        { id: "editor", text: "Editor" },
                                    ],
                                },
                                {
                                    text: "Remove",
                                    id: "remove",
                                }
                            ]}
                            onItemClick={(event) => {

                                switch (event.detail.id) {
                                    case 'string': {
                                        const newTargetScript = produce(props.targetScript, (draft) => {
                                            draft.parameters[index].value = "";
                                            draft.parameters[index].type = "string";
                                        });
                                        props.setTargetScript(newTargetScript);
                                        return;
                                    }
                                    case 'file': {
                                        const newTargetScript = produce(props.targetScript, (draft) => {
                                            draft.parameters[index].value = "";
                                            draft.parameters[index].type = "file";
                                        });
                                        props.setTargetScript(newTargetScript);
                                        return;
                                    }
                                    case 'editor': {
                                        const newTargetScript = produce(props.targetScript, (draft) => {
                                            draft.parameters[index].value = "";
                                            draft.parameters[index].type = "editor";
                                        });
                                        props.setTargetScript(newTargetScript);
                                        return;
                                    }
                                }
                            }}
                        ></ButtonDropdown>
                    </div>}
                >
                    <Input
                        value={parameter.name}
                        onChange={(event) => {
                            const newTargetScript = produce(props.targetScript, (draft) => {
                                draft.parameters[index].name = event.detail.value;
                            });
                            props.setTargetScript(newTargetScript);
                        }}
                    />
                </FormField>
                <FormField
                    label="Value"
                >
                    <ToolParametersInputView
                        index={index}
                        parameter={parameter}
                        targetScript={props.targetScript}
                        setTargetScript={props.setTargetScript}
                    />
                </FormField>
            </Container>;
        })}
    </SpaceBetween>
};
