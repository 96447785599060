import { Button, Container, Form, FormField, Header, Input, SpaceBetween } from "@cloudscape-design/components";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { callRecipePut } from "../proxy/recipe-put";

export type NewRecipeViewProps = {

    readonly updateInventory: () => void;
};

export const NewRecipeView: React.FC<NewRecipeViewProps> = (props: NewRecipeViewProps) => {

    const navigate = useNavigate();
    const [recipeNameValue, setRecipeNameValue] = useState("");

    const [loading, setLoading] = useState(false);

    return <div
        style={{
            margin: '16px',
        }}
    >
        <Container>
            <form onSubmit={async (event) => {

                event.preventDefault();

                setLoading(true);
                const response = await callRecipePut({
                    title: recipeNameValue,
                    script: "",
                    declaration: "",
                });
                setLoading(false);
                props.updateInventory();

                navigate(`/recipe/${response.identifier}`);
            }}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                variant="primary"
                                loading={loading}
                            >
                                Submit
                            </Button>
                        </SpaceBetween>
                    }
                    header={<Header variant="h1">Create New Recipe</Header>}
                >
                    <FormField
                        description="Recipe name"
                    >
                        <Input
                            value={recipeNameValue}
                            onChange={event =>
                                setRecipeNameValue(event.detail.value)
                            }
                        />
                    </FormField>
                </Form>
            </form>
        </Container>
    </div>;
};
