import { Monaco } from "@monaco-editor/react";
import { Sandbox } from "@sudoo/marked";
import { SandboxController } from "../controller";

const moduleName = "module/log";

export const mountLogModule = (sandbox: Sandbox) => {

    sandbox.provide(moduleName, {
        "log": (controller: SandboxController, ...data: any[]) => {



            controller.pushLog([
                data.map((each) => {
                    return typeof each === 'object'
                        ? JSON.stringify(each)
                        : each;
                }).join(' '),
            ]);
        },
    });
};

export const mountLogMonaco = (monaco: Monaco) => {

    const source = [
        `declare module '${moduleName}' {`,
        `declare function log(...data: any[]):void;`,
        `}`
    ].join('');

    monaco.languages.typescript.javascriptDefaults.addExtraLib(source, moduleName);
};
