import { requestHelper } from "./request";

export type HelperRunCommandsProxyResponse = {

    readonly stderr: string[];
    readonly stdout: string[];
};

export const helperRunCommandsProxy = async (
    bridgeHost: string,
    targetHost: string,
    commands: string[],
): Promise<HelperRunCommandsProxyResponse> => {

    return requestHelper('/action/remote-command', {
        bridgeHost,
        targetHost,
        commands,
    });
};
