import { Monaco } from '@monaco-editor/react';
import { markedDateMonacoMixinFactory } from '@sudoo/marked-mixin-date-monaco';
import { markedMathMonacoMixinFactory } from '@sudoo/marked-mixin-math-monaco';
import { markedObjectMonacoMixinFactory } from '@sudoo/marked-mixin-object-monaco';
import { MarkedMonacoManager } from '@sudoo/marked-monaco';
import { RecipeObject, ScriptParameter } from '../storage/declare';
import { getRecipeDeclaration } from './declaration';
import { mountDatabaseMonaco } from './modules/database';
import { mountLogMonaco } from './modules/log';
import { mountSimMonaco } from './modules/sim';
import { mountSleepMonaco } from './modules/sleep';
import { mountSSHMonaco } from './modules/ssh';
import { mountWidgetMonaco } from './modules/widget';

export const mountEditorConfigs = async (monaco: Monaco) => {

    const manager: MarkedMonacoManager = MarkedMonacoManager.createAndMountBase(monaco, 'javascript');

    manager.use(markedDateMonacoMixinFactory.createInjectMixin("Date"));
    manager.use(markedMathMonacoMixinFactory.createInjectMixin("Math"));
    manager.use(markedObjectMonacoMixinFactory.createInjectMixin("Object"));
};

export const mountEditorModules = (monaco: Monaco) => {

    mountDatabaseMonaco(monaco);
    mountLogMonaco(monaco);
    mountSimMonaco(monaco);
    mountSleepMonaco(monaco);
    mountSSHMonaco(monaco);
    mountWidgetMonaco(monaco);
};

export const mountGlobalVariables = (monaco: Monaco, parameters: ScriptParameter[]) => {

    monaco.languages.typescript.javascriptDefaults.addExtraLib([
        `declare const parameters: {`,
        ...parameters.map((parameter) => {
            if (parameter.type === 'string') {
                return `"${parameter.name}": string,`;
            }
            if (parameter.type === 'file') {
                return `"${parameter.name}": {
                    getType: () => string;
                    asRaw: () => string;
                    asBinary: () => string;
                    asString: () => string;
                    asCSV: () => any;
                },`;
            }
            return `"${parameter.name}": ${parameter.type},`;
        }),
        `}`,
        `declare const environments: {`,
        `region: string,`,
        `stage: string,`,
        `alias: string,`,
        `}`,
    ].join(''), 'global.d.ts');
};

export const mountRecipes = async (monaco: Monaco, recipes: RecipeObject[]) => {

    for (const recipe of recipes) {

        const declaration: string = await getRecipeDeclaration(recipe);
        monaco.languages.typescript.javascriptDefaults.addExtraLib(declaration, `recipe/${recipe.owner}/${recipe.name}`);
        monaco.languages.typescript.javascriptDefaults.addExtraLib(declaration, `recipe/${recipe.identifier}`);
    }
};
