import { Button } from "@cloudscape-design/components";
import produce from "immer";
import * as React from "react";
import { ScriptObject, ScriptParameter } from "../../storage/declare";

export type ToolParametersFileViewProps = {

    readonly index: number;
    readonly parameter: ScriptParameter;
    readonly targetScript: ScriptObject;
    readonly setTargetScript: (next: ScriptObject) => void;
};

export const ToolParametersFileView: React.FC<ToolParametersFileViewProps> = (props: ToolParametersFileViewProps) => {

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleClick = () => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const handleChange = (event: any) => {
        const fileUploaded = event.target.files[0];

        const reader = new FileReader();
        reader.readAsDataURL(fileUploaded);
        reader.onload = function () {
            const newTargetScript = produce(props.targetScript, (draft) => {
                if (!reader.result) {
                    return draft;
                }
                draft.parameters[props.index].value = reader.result.toString();
            });
            props.setTargetScript(newTargetScript);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    return (<React.Fragment>
        <Button
            variant="primary"
            onClick={handleClick}
        >
            Select File
        </Button>
        <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
        />
    </React.Fragment>);
};
