import { callRecipeGet } from "../../proxy/recipe-get";
import { callRecipePut } from "../../proxy/recipe-put";
import { RecipeObject } from "../../storage/declare";

export class RecipeCacheManager {

    private static _instance: RecipeCacheManager | null = null;

    public static getInstance(): RecipeCacheManager {

        if (this._instance === null) {
            this._instance = new RecipeCacheManager();
        }
        return this._instance;
    }

    private readonly recipeMap: Map<string, RecipeObject>;
    private readonly ownerMap: Map<string, string>;

    private constructor() {

        this.recipeMap = new Map();
        this.ownerMap = new Map();
    }

    public async getRecipeByIdentifier(identifier: string): Promise<RecipeObject> {

        if (this.recipeMap.has(identifier)) {
            return this.recipeMap.get(identifier)!;
        }

        const response = await callRecipeGet({
            identifier,
        });

        this.recipeMap.set(response.identifier, response);
        this.ownerMap.set(response.owner, response.identifier);

        return response;
    }

    public tapRecipeCheckedByIdentifier(identifier: string): RecipeObject | null {

        return this.recipeMap.get(identifier) ?? null;
    }

    public async getRecipeByOwner(owner: string, title: string): Promise<RecipeObject> {

        const hash: string = this._buildOwnerTitleHash(owner, title);

        if (this.ownerMap.has(hash)) {
            return this.recipeMap.get(this.ownerMap.get(hash)!)!;
        }

        const response = await callRecipeGet({
            owner,
            title,
        });

        this.recipeMap.set(response.identifier, response);
        this.ownerMap.set(hash, response.identifier);

        return response;
    }

    public tapRecipeCachedByOwner(owner: string, title: string): RecipeObject | null {

        const hash: string = this._buildOwnerTitleHash(owner, title);
        if (this.ownerMap.has(hash)) {
            return this.recipeMap.get(this.ownerMap.get(hash)!)!;
        }
        return null;
    }

    public async putRecipe(recipe: RecipeObject): Promise<void> {

        const response = await callRecipePut({
            identifier: recipe.identifier,
            title: recipe.name,
            script: recipe.script,
            declaration: recipe.declaration,
        });

        const hash: string = this._buildOwnerTitleHash(recipe.owner, recipe.name);

        this.recipeMap.set(response.identifier, recipe);
        this.ownerMap.set(hash, response.identifier);
    }

    public getCachedRecipes(): RecipeObject[] {

        return [...this.recipeMap.values()];
    }

    private _buildOwnerTitleHash(owner: string, title: string) {

        return `${owner}/${title}`;
    }
}
