import Axios from "axios";

export const requestHelper = async (path: string, data: Record<string, any>): Promise<any> => {

    const fixedPath: string = path.startsWith("/")
        ? path
        : `/${path}`;

    try {
        const url = `http://localhost:65500${fixedPath}`;

        const response = await Axios.post(url, data);

        return response.data;
    } catch (error) {

        console.log(error);

        throw new Error("Helper request failed");
    }
};
