import "@cloudscape-design/global-styles/index.css";
import * as React from "react";
import { HashRouter } from 'react-router-dom';
import { LayoutView } from './layout';

export const App: React.FC = () => {

  return (
    <HashRouter>
      <LayoutView />
    </HashRouter>
  );
};
