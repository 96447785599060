import * as React from "react";
import { ScriptObject } from "../storage/declare";

export type CurrentOpeningScriptContextValue = {

    readonly script: ScriptObject | null;
    readonly setScript: (newScript: ScriptObject | null) => void;
};

export const CurrentOpeningScriptContext: React.Context<CurrentOpeningScriptContextValue> =
    React.createContext<CurrentOpeningScriptContextValue>({
        script: null,
        setScript: () => { },
    });

export const useCurrentOpeningScriptContext = (): CurrentOpeningScriptContextValue => {

    const context: CurrentOpeningScriptContextValue = React.useContext(CurrentOpeningScriptContext);
    return context;
};
