import { emitTypeScriptDeclaration } from "@sudoo/marked";
import { RecipeObject } from "../storage/declare";

const declarationCache: Map<string, string> = new Map();

export const removeRecipeDeclarationCache = (identifier: string): void => {

    declarationCache.delete(identifier);
};

export const getRecipeDeclaration = async (recipe: RecipeObject): Promise<string> => {

    if (declarationCache.has(recipe.identifier)) {
        return declarationCache.get(recipe.identifier) as string;
    }

    const declaration: string = await emitTypeScriptDeclaration(recipe.script);
    const wrapped: string = [
        `declare module 'recipe/${recipe.owner}/${recipe.name}' {`,
        declaration,
        `}`,
        `declare module 'recipe/${recipe.identifier}' {`,
        declaration,
        `}`,
    ].join('');

    declarationCache.set(recipe.identifier, wrapped);

    return wrapped;
};
