import { Button, Modal } from "@cloudscape-design/components";
import produce from "immer";
import * as React from "react";
import { ScriptObject, ScriptParameter } from "../../storage/declare";
import Editor, { Monaco, OnMount } from "@monaco-editor/react";
import { mountEditorConfigs } from "../../editor/monaco";

export type ToolParametersEditorViewProps = {

    readonly index: number;
    readonly parameter: ScriptParameter;
    readonly targetScript: ScriptObject;
    readonly setTargetScript: (next: ScriptObject) => void;
};

export const ToolParametersEditorView: React.FC<ToolParametersEditorViewProps> = (props: ToolParametersEditorViewProps) => {

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleEditorDidMount: OnMount = (editor: any, monaco: Monaco) => {

        mountEditorConfigs(monaco);
    };

    const handleChange = (newCode: string | undefined) => {

        if (typeof newCode !== 'string') {
            return;
        }

        const newTargetScript = produce(props.targetScript, (draft) => {
            draft.parameters[props.index].value = newCode;
        });
        props.setTargetScript(newTargetScript);
    };

    return (<React.Fragment>
        <Button
            variant="primary"
            onClick={handleClick}
        >
            Open Editor
        </Button>
        <Modal
            size="large"
            visible={open}
            onDismiss={() => setOpen(false)}
            header={`Parameter ${props.parameter.name}`}
        >
            <Editor
                height={480}
                defaultLanguage="json"
                options={{
                    lineNumbers: "off",
                    readOnly: false,
                    wordWrap: "on",
                    minimap: {
                        enabled: false,
                    },
                }}
                value={props.parameter.value}
                onChange={handleChange}
                onMount={handleEditorDidMount}
            />
        </Modal>
    </React.Fragment>);
};
