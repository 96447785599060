import { Monaco } from "@monaco-editor/react";
import { Sandbox } from "@sudoo/marked";
import { SandboxController } from "../controller";

const moduleName = "module/sleep";

export const mountSleepModule = (sandbox: Sandbox) => {

    sandbox.provide(moduleName, {
        "sleep": (controller: SandboxController, time: number): Promise<void> => {

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve();
                }, time);
            });
        },
    });
};

export const mountSleepMonaco = (monaco: Monaco) => {

    const source = [
        `declare module '${moduleName}' {`,
        `declare function sleep(time: number):void;`,
        `}`
    ].join('');

    monaco.languages.typescript.javascriptDefaults.addExtraLib(source, moduleName);
};
