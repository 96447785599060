import { StatusIndicator } from "@cloudscape-design/components";
import { Monaco } from "@monaco-editor/react";
import { formatTypeScriptCode } from "@sudoo/marked";
import * as React from "react";
import { MutableRefObject } from "react";
import { useLocation } from "react-router-dom";
import { useCurrentOpeningScriptContext } from "../context/current-opening-script";
import { EditorView } from "../editor/editor";
import { callScriptGet } from "../proxy/script-get";
import { callScriptPut } from "../proxy/script-put";
import { EditorHeaderView } from "./editor-header";

export type EditorWrapperScriptViewProps = {

    readonly editorRef: MutableRefObject<any | null>;
    readonly monacoRef: MutableRefObject<Monaco | null>;
};

export const EditorWrapperScriptView: React.FC<EditorWrapperScriptViewProps> = (props: EditorWrapperScriptViewProps) => {

    const location = useLocation();
    const identifier: string = location.pathname.substring(8);

    const [loading, setLoading] = React.useState(true);
    const currentOpeningScript = useCurrentOpeningScriptContext();

    React.useEffect(() => {

        (async () => {

            setLoading(true);
            currentOpeningScript.setScript(null);
            try {

                const response = await callScriptGet(identifier);
                currentOpeningScript.setScript(response);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [identifier]);

    if (loading) {
        return <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <StatusIndicator
                type="loading"
            >
                Loading
            </StatusIndicator>
        </div>
    }

    if (currentOpeningScript.script === null) {
        return <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <StatusIndicator
                type="error"
            >
                Script {identifier} Not Found
            </StatusIndicator>
        </div>
    }

    return <div>
        <div style={{
            height: '60px',
        }}>
            <EditorHeaderView
                scriptName={currentOpeningScript.script.name}
                onFormatAndSave={async () => {
                    if (currentOpeningScript.script === null) {
                        return;
                    }

                    const formatted: string = await formatTypeScriptCode(currentOpeningScript.script.code);

                    await callScriptPut({
                        identifier: currentOpeningScript.script.identifier,
                        title: currentOpeningScript.script.name,
                        script: formatted,
                        parameters: currentOpeningScript.script.parameters,
                    });

                    currentOpeningScript.setScript({
                        ...currentOpeningScript.script,
                        code: formatted,
                    });
                }}
                onDelete={() => {
                    // Delete not supported
                }}
            />
        </div>
        <div style={{
            height: 'calc(100vh - 60px)',
        }}>
            <EditorView
                editorRef={props.editorRef}
                monacoRef={props.monacoRef}
                code={currentOpeningScript.script.code}
                setCode={(newCode: string) => {
                    if (currentOpeningScript.script === null) {
                        return;
                    }

                    currentOpeningScript.setScript({
                        ...currentOpeningScript.script,
                        code: newCode,
                    });
                }}
                parameters={currentOpeningScript.script.parameters}
            />
        </div>
    </div>
};
