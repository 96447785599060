import { Monaco } from "@monaco-editor/react";
import { Sandbox } from "@sudoo/marked";
import { SandboxController } from "../controller";

const moduleName = "module/database";

export const mountDatabaseModule = (sandbox: Sandbox) => {

    sandbox.provide(moduleName, {
        "connectDatabase": (controller: SandboxController, url: string) => {

            return {
                query: (controller: SandboxController, query: string) => {

                    if (query.includes("00002")) {
                        return {
                            id: "000-00000-00002",
                            marketplaceId: "12345",
                            chargebackState: "Pending",
                            orderType: "SHIPMENT_ID",
                            highLevelReason: "FRAUD",
                            somethingWentWrong: false,
                        }
                    } else {
                        return {
                            id: "000-00000-00001",
                            marketplaceId: "12345",
                            chargebackState: "Canceld",
                            orderType: "XXXXX",
                            highLevelReason: "FRAUD",
                            somethingWentWrong: true,
                        }
                    }
                },
                close: () => void 0,
            }
        },
    });
};

export const mountDatabaseMonaco = (monaco: Monaco) => {

    const source = [
        `declare module '${moduleName}' {`,
        `declare function connectDatabase(url:string): {query:(sql: string)=>any,close:()=>undefined};`,
        `}`,
    ].join('');

    monaco.languages.typescript.javascriptDefaults.addExtraLib(source, moduleName);
};
