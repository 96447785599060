import { Sandbox, ScriptLocation } from "@sudoo/marked";
import { markedDateMixinFactory } from "@sudoo/marked-mixin-date";
import { markedMathMixinFactory } from "@sudoo/marked-mixin-math";
import { markedObjectMixinFactory } from "@sudoo/marked-mixin-object";
import { RecipeObject } from "../storage/declare";
import { SandboxController } from "./controller";
import { mountDatabaseModule } from "./modules/database";
import { mountLogModule } from "./modules/log";
import { mountSimModule } from "./modules/sim";
import { mountSleepModule } from "./modules/sleep";
import { mountSSHModule } from "./modules/ssh";
import { mountWidgetModule } from "./modules/widget";
import { RecipeCacheManager } from "./recipe-cache/recipe-cache-manager";

export const createSandbox = (
    controller: SandboxController,
    parameters: Record<string, any>,
    environments: Record<string, any>,
) => {

    const sandbox: Sandbox = Sandbox.fromAllEvaluators('typescript');

    sandbox.setOption("maxCodeLength", 1000000);

    sandbox.use(markedDateMixinFactory.createInjectMixin("Date"));
    sandbox.use(markedMathMixinFactory.createInjectMixin("Math"));
    sandbox.use(markedObjectMixinFactory.createInjectMixin("Object"));

    sandbox.setAdditionalArgument(controller);
    sandbox.inject('parameters', parameters);
    sandbox.inject('environments', environments);

    sandbox.resolver(async (source: string) => {

        if (!source.startsWith("recipe/")) {
            return null;
        }

        const sourceName: string = source.slice(7);

        const splitWithSlash = sourceName.split('/');

        if (splitWithSlash.length === 1) {

            const recipe: RecipeObject = await RecipeCacheManager
                .getInstance()
                .getRecipeByIdentifier(sourceName);

            return {
                script: recipe.script,
                scriptLocation: ScriptLocation.create('recipe', recipe.identifier),
            };
        }

        const recipe: RecipeObject = await RecipeCacheManager
            .getInstance()
            .getRecipeByOwner(splitWithSlash[0], splitWithSlash[1]);

        return {
            script: recipe.script,
            scriptLocation: ScriptLocation.create('recipe', recipe.identifier),
        };
    });

    mountDatabaseModule(sandbox);
    mountLogModule(sandbox);
    mountSimModule(sandbox);
    mountSleepModule(sandbox);
    mountSSHModule(sandbox);
    mountWidgetModule(sandbox);

    return sandbox;
};
