import { Container, Header, SpaceBetween } from "@cloudscape-design/components";
import * as React from "react";

export const HeaderView = () => {
    return <div
        style={{
            padding: '32px',
        }}
    >
        <SpaceBetween size="l">
            <Header
                variant="h1"
            >
                Welcome
            </Header>
            <Container>
                Select a script or create a new script from the navigation panel to get started.
            </Container>
        </SpaceBetween>
    </div>;
}
