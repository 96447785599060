import { Monaco } from "@monaco-editor/react";
import { MarkedDebugInterceptor } from "@sudoo/marked";
import Papa from "papaparse";
import { Dispatch, SetStateAction } from "react";
import { SandboxController } from "../editor/controller";
import { createSandbox } from "../editor/sandbox";
import { ResultBlock } from "../result/declare";
import { ScriptObject } from "../storage/declare";
import { appendResultBlock } from "./append-result-block";

export type ExecuteScriptOptions = {

    readonly targetScript: ScriptObject;

    readonly setResultBlocks: Dispatch<SetStateAction<ResultBlock[]>>;

    readonly editor: any;
    readonly monaco: Monaco;

    readonly region: string;
    readonly stage: string;
};

export const executeScript = async (options: ExecuteScriptOptions) => {

    let currentDecorations: string[] = [];

    const controller: SandboxController = SandboxController.create((block: ResultBlock) => {
        options.setResultBlocks((previous) => {
            return appendResultBlock(previous, block);
        });
    }, options.region, options.stage)
    const sandbox = createSandbox(controller, options.targetScript.parameters.reduce((previous, each) => {

        if (each.type === 'string') {
            return {
                ...previous,
                [each.name]: each.value,
            };
        }
        if (each.type === 'file') {

            const split: string[] = each.value.split(',');
            const baseTypeWithBase64: string = split[0];
            const mimeType: string = baseTypeWithBase64.split(';')[0];
            const base64: string = split[1];

            return {
                ...previous,
                [each.name]: {
                    getType: () => mimeType,
                    asRaw: () => each.value,
                    asBinary: () => base64,
                    asString: () => atob(base64),
                    asCSV: () => Papa.parse(atob(base64)).data,
                },
            };
        }

        return {
            ...previous,
            [each.name]: each.value,
        };
    }, {}), {
        region: options.region,
        stage: options.stage,
        alias: 'mengwcp',
    });

    const interceptor = MarkedDebugInterceptor.fromListener((snapshot, flowController) => {

        currentDecorations = options.editor.deltaDecorations(currentDecorations, [
            {
                range: new options.monaco.Range(
                    snapshot.location.startPosition.line,
                    snapshot.location.startPosition.column + 1,
                    snapshot.location.endPosition.line,
                    snapshot.location.endPosition.column + 1,
                ),
                options: {
                    className: 'bg-red-200',
                },
            },
        ]);

        options.setResultBlocks((previous) => {
            return appendResultBlock(previous, {
                type: 'debug',
                snapshot,
                flowController,
                targetScript: options.targetScript,
            });
        });
    });

    sandbox.setDebugInterceptor(interceptor);

    const result = await sandbox.evaluate(options.targetScript.code);

    console.log(result);

    options.setResultBlocks((previous) => {
        return appendResultBlock(previous, {
            type: 'final',
            result,
        });
    });

    currentDecorations = options.editor.deltaDecorations(currentDecorations, []);
};
