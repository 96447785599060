import { Button, Container, Form, FormField, Header, Input, SpaceBetween } from "@cloudscape-design/components";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { callScriptPut } from "../proxy/script-put";

export type NewScriptViewProps = {

    readonly updateInventory: () => void;
};

export const NewScriptView: React.FC<NewScriptViewProps> = (props: NewScriptViewProps) => {

    const navigate = useNavigate();
    const [scriptNameValue, setScriptNameValue] = useState("");

    const [submitting, setSubmitting] = useState(false);

    return <div
        style={{
            margin: '16px',
        }}
    >
        <Container>
            <form onSubmit={async (event) => {

                event.preventDefault();

                setSubmitting(true);
                const response = await callScriptPut({
                    title: scriptNameValue,
                    script: "",
                    parameters: [],
                });
                setSubmitting(false);
                props.updateInventory();

                navigate(`/script/${response.identifier}`);
            }}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                variant="primary"
                                loading={submitting}
                            >
                                Submit
                            </Button>
                        </SpaceBetween>
                    }
                    header={<Header variant="h1">Create New Script</Header>}
                >
                    <FormField
                        description="Script name"
                    >
                        <Input
                            value={scriptNameValue}
                            onChange={event =>
                                setScriptNameValue(event.detail.value)
                            }
                        />
                    </FormField>
                </Form>
            </form>
        </Container>
    </div>;
};
