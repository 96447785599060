import * as React from "react";
import { ResultBlock } from "./declare";
import { DebugResultSectionView } from "./section/debug";
import { DiffResultSectionView } from "./section/diff";
import { FinalResultSectionView } from "./section/final-result";
import { ListResultSectionView } from "./section/list";
import { LogResultSectionView } from "./section/log";

export type ResultSectionViewProps = {

    readonly block: ResultBlock;
};

export const ResultSectionView: React.FC<ResultSectionViewProps> = (props: ResultSectionViewProps) => {

    switch (props.block.type) {
        case "final": return <FinalResultSectionView
            result={props.block.result}
        />
        case "list": return <ListResultSectionView
            elements={props.block.elements}
        />
        case "log": return <LogResultSectionView
            lines={props.block.value}
        />
        case "debug": return <DebugResultSectionView
            snapshot={props.block.snapshot}
            flowController={props.block.flowController}
            targetScript={props.block.targetScript}
        />
        case "diff": return <DiffResultSectionView
            original={props.block.original}
            modified={props.block.modified}
        />
    }

    return null;
};
