import { Button, HelpPanel, SegmentedControl, SpaceBetween } from "@cloudscape-design/components";
import { Monaco } from "@monaco-editor/react";
import * as React from "react";
import { MutableRefObject, useRef, useState } from "react";
import { useCurrentOpeningScriptContext } from "../context/current-opening-script";
import { ResultBlock } from "../result/declare";
import { ResultView } from "../result/result";
import { executeScript } from "./execute";
import { ToolEnvironmentView } from "./tool-environments";
import { ToolParametersView } from "./tool-parameters";
import { ToolRecipeCacheView } from "./tool-recipe-cache";

export type ToolViewProps = {

    readonly editorRef: MutableRefObject<any | null>;
    readonly monacoRef: MutableRefObject<Monaco | null>;
};

export const ToolView: React.FC<ToolViewProps> = (props: ToolViewProps) => {

    const [opening, setOpening] = useState(false);
    const [resultBlocks, setResultBlocks] = useState<Array<ResultBlock>>([]);
    const [completed, setCompleted] = useState(true);

    const refOpening = useRef(false);

    const [region, setRegion] = useState("NA");
    const [stage, setStage] = useState("Alpha");

    const currentOpeningScript = useCurrentOpeningScriptContext();

    const [currentSegment, setCurrentSegment] = useState("execute");

    if (currentOpeningScript.script === null) {
        return <HelpPanel
            loading
            loadingText="Loading Script"
        />;
    }

    return <HelpPanel
        header="Script"
    >
        <ResultView
            visible={opening}
            onClose={() => {
                setOpening(false);
                refOpening.current = false;
                if (completed) {
                    setResultBlocks([]);
                }
            }}
            resultBlocks={resultBlocks}
            completed={completed}
            succeed={true}
        />
        <SpaceBetween size="s">
            <SegmentedControl
                selectedId={currentSegment}
                onChange={({ detail }) => {
                    setCurrentSegment(detail.selectedId);
                }}
                options={[
                    {
                        text: "Execute",
                        id: "execute",
                    },
                    {
                        text: "Metadata",
                        id: "metadata",
                    },
                ]}
            />
            {currentSegment === "metadata" ? <ToolRecipeCacheView /> : null}
            {currentSegment === "execute" ? <ToolEnvironmentView
                region={region}
                setRegion={setRegion}
                stage={stage}
                setStage={setStage}
            /> : null}
            {currentSegment === "execute" ? <ToolParametersView
                targetScript={currentOpeningScript.script}
                setTargetScript={(newTargetScript) => {
                    currentOpeningScript.setScript(newTargetScript);
                }}
            /> : null}
            {currentSegment === "execute" ? <Button
                disabled={!completed}
                onClick={() => {
                    currentOpeningScript.setScript({
                        ...currentOpeningScript.script!,
                        parameters: [
                            ...currentOpeningScript.script!.parameters,
                            {
                                type: 'string',
                                name: `parameter${Math.random().toString().substring(2, 5)}`,
                                value: '',
                            },
                        ],
                    });
                }}>
                Add Parameter
            </Button> : null}
            {completed
                ? <Button
                    variant="primary"
                    onClick={async () => {

                        setOpening(true);
                        refOpening.current = true;
                        setCompleted(false);

                        if (props.editorRef.current === null
                            || props.monacoRef.current === null) {
                            return;
                        }

                        if (currentOpeningScript.script === null) {
                            return;
                        }

                        await executeScript({
                            targetScript: currentOpeningScript.script,
                            setResultBlocks,
                            editor: props.editorRef.current,
                            monaco: props.monacoRef.current,
                            region,
                            stage,
                        });

                        setCompleted(true);
                        if (!refOpening.current) {
                            setResultBlocks([]);
                        }
                    }}>
                    Execute
                </Button>
                : <Button
                    variant="primary"
                    onClick={async () => {

                        setOpening(true);
                        refOpening.current = true;
                    }}>
                    Open Result Panel
                </Button>}
        </SpaceBetween>
    </HelpPanel>
};
