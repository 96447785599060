import { Button, ButtonDropdown, Header, Popover, SpaceBetween, StatusIndicator } from "@cloudscape-design/components";
import * as React from "react";

export type EditorHeaderViewProps = {

    readonly scriptName: string;

    readonly onFormatAndSave: () => void;
    readonly onDelete: () => void;
};

export const EditorHeaderView: React.FC<EditorHeaderViewProps> = (props: EditorHeaderViewProps) => {

    return <div style={{
        padding: '8px',
        paddingLeft: '16px',
        paddingRight: '16px',
    }}>
        <Header
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Popover
                        size="small"
                        position="top"
                        triggerType="custom"
                        dismissButton={false}
                        content={<StatusIndicator type="success">Copied</StatusIndicator>}
                    >
                        <Button
                            iconName="copy"
                            onClick={() => {
                                navigator.clipboard.writeText(location.href);
                            }}
                        >
                            Copy Link
                        </Button>
                    </Popover>
                    <ButtonDropdown
                        variant="primary"
                        items={[
                            {
                                text: "Delete",
                                id: "delete",
                                disabled: false,
                            },
                        ]}
                        onItemClick={(event) => {
                            if (event.detail.id === "delete") {
                                props.onDelete();
                            }
                        }}
                    >
                        Actions
                    </ButtonDropdown>
                </SpaceBetween>
            }
        >
            <div
                style={{
                    display: 'inline',
                    padding: '8px',
                }}
                className="underline"
            >
                <Button
                    iconName="upload"
                    onClick={props.onFormatAndSave}
                >
                    Save
                </Button>
            </div>
            <div style={{
                display: 'inline',
            }}>
                {props.scriptName}
            </div>
        </Header >
    </div >
};
