import { Monaco } from "@monaco-editor/react";
import { Sandbox } from "@sudoo/marked";
import { SandboxController } from "../controller";

const moduleName = "module/sim";

export const mountSimModule = (sandbox: Sandbox) => {

    sandbox.provide(moduleName, {
        "getCurrentSprint": (controller: SandboxController) => {

            return {
                sprint: `sprint 55`,
            }
        },
        "getTasks": (controller: SandboxController, sprint: string, alias: string) => {

            return [
                {
                    taskName: "[TASK] test task 1",
                    href: "https://sim.amazon.com/issues/119fbfa6-a663-4ba0-b726-1276714ccce6",
                    status: "implement",
                },
                {
                    taskName: "[OE] test task 2",
                    href: "https://sim.amazon.com/issues/119fbfa6-a663-4ba0-b726-1276714ccce6",
                    status: "implement",
                },
            ];
        },
    });
};

export const mountSimMonaco = (monaco: Monaco) => {

    const source = [
        `declare module '${moduleName}' {`,
        `declare type TaskItem = {taskName: string, href: string, status: string}`,
        `declare function getCurrentSprint(): {sprint: string};`,
        `declare function getTasks(sprint: string, alias: string): TaskItem[];`,
        `}`
    ].join('');

    monaco.languages.typescript.javascriptDefaults.addExtraLib(source, moduleName);
};
