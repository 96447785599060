import { StatusIndicator } from "@cloudscape-design/components";
import { Monaco } from "@monaco-editor/react";
import { formatTypeScriptCode } from "@sudoo/marked";
import * as React from "react";
import { MutableRefObject } from "react";
import { useLocation } from "react-router-dom";
import { getRecipeDeclaration } from "../editor/declaration";
import { EditorView } from "../editor/editor";
import { RecipeCacheManager } from "../editor/recipe-cache/recipe-cache-manager";
import { RecipeObject } from "../storage/declare";
import { EditorHeaderView } from "./editor-header";

export type EditorWrapperRecipeViewProps = {

    readonly editorRef: MutableRefObject<any | null>;
    readonly monacoRef: MutableRefObject<Monaco | null>;
};

export const EditorWrapperRecipeView: React.FC<EditorWrapperRecipeViewProps> = (props: EditorWrapperRecipeViewProps) => {

    const location = useLocation();
    const identifier: string = location.pathname.substring(8);

    const [loading, setLoading] = React.useState(true);
    const [recipe, setRecipe] = React.useState<null | RecipeObject>(null);

    React.useEffect(() => {

        (async () => {
            setLoading(true);
            setRecipe(null);
            try {
                const response = await RecipeCacheManager
                    .getInstance()
                    .getRecipeByIdentifier(identifier);

                setRecipe(response);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [identifier]);

    if (loading) {
        return <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <StatusIndicator
                type="loading"
            >
                Loading
            </StatusIndicator>
        </div>
    }

    if (recipe === null) {
        return <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <StatusIndicator
                type="error"
            >
                Recipe {identifier} Not Found
            </StatusIndicator>
        </div>
    }

    return <div>
        <div style={{
            height: '60px',
        }}>
            <EditorHeaderView
                scriptName={recipe.name}
                onFormatAndSave={async () => {
                    const formatted: string = await formatTypeScriptCode(recipe.script);
                    const declaration: string = await getRecipeDeclaration(recipe);

                    await RecipeCacheManager.getInstance().putRecipe({
                        identifier: recipe.identifier,
                        name: recipe.name,
                        owner: recipe.owner,
                        script: formatted,
                        declaration,
                    });
                }}
                onDelete={() => {
                    // delete not supported yet
                }}
            />
        </div>
        <div style={{
            height: 'calc(100vh - 60px)',
        }}>
            <EditorView
                editorRef={props.editorRef}
                monacoRef={props.monacoRef}
                currentRecipeIdentifier={recipe.identifier}
                code={recipe.script}
                setCode={(newCode: string) => {
                    setRecipe({
                        ...recipe,
                        script: newCode,
                    });
                }}
            />
        </div>
    </div>
};
