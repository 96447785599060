import { ResultBlock, ResultLogBlockLine } from "../result/declare";

export type SandboxControllerBlockListener = (block: ResultBlock) => void;

export class SandboxController {

    public static create(
        blockListener: SandboxControllerBlockListener,
        region: string,
        stage: string,
    ) {

        return new SandboxController(blockListener, region, stage);
    }

    private readonly _blockListener: SandboxControllerBlockListener;

    private readonly _region: string;
    private readonly _stage: string;

    private constructor(
        blockListener: SandboxControllerBlockListener,
        region: string,
        stage: string,
    ) {

        this._blockListener = blockListener;

        this._region = region;
        this._stage = stage;
    }

    public get region(): string {
        return this._region;
    }
    public get stage(): string {
        return this._stage;
    }

    public pushLog(lines: string[]): this {

        return this.pushBlock({
            type: 'log',
            value: lines.map((line): ResultLogBlockLine => {
                return {
                    timestamp: Date.now(),
                    text: line,
                };
            }),
        });
    }

    public pushBlock(block: ResultBlock): this {

        this._blockListener(block);
        return this;
    }
}
