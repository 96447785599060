import { Monaco } from "@monaco-editor/react";
import { Sandbox } from "@sudoo/marked";
import { helperRunCommandsProxy, HelperRunCommandsProxyResponse } from "../../helper/run-command";
import { SandboxController } from "../controller";

const moduleName = "module/ssh";

export const mountSSHModule = (sandbox: Sandbox) => {

    sandbox.provide(moduleName, {
        runRemoteCommands: async (
            controller: SandboxController,
            bridgeHost: string,
            targetHost: string,
            commands: string[],
        ): Promise<HelperRunCommandsProxyResponse> => {

            const result = await helperRunCommandsProxy(bridgeHost, targetHost, commands);
            return result;
        },
    });
};

export const mountSSHMonaco = (monaco: Monaco) => {

    const source = [
        `declare module '${moduleName}' {`,
        `type RunRemoteCommandsResult = {stderr: string[]; readonly stdout: string[];}`,
        `declare function runRemoteCommands(bridgeHost: string,targetHost: string,commands: string[]): RunRemoteCommandsResult`,
        `}`,
    ].join('');

    monaco.languages.typescript.javascriptDefaults.addExtraLib(source, moduleName);
};
