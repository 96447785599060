import * as React from "react";
import { ScriptObject, ScriptParameter } from "../../storage/declare";
import { ToolParametersFileView } from "./parameter-file";
import { ToolParametersStringView } from "./parameter-string";
import { ToolParametersEditorView } from "./parameter-editor";

export type ToolParametersInputViewProps = {

    readonly index: number;
    readonly parameter: ScriptParameter;
    readonly targetScript: ScriptObject;
    readonly setTargetScript: (next: ScriptObject) => void;
};

export const ToolParametersInputView: React.FC<ToolParametersInputViewProps> = (props: ToolParametersInputViewProps) => {

    if (props.targetScript.parameters[props.index].type === 'string') {
        return <ToolParametersStringView
            index={props.index}
            parameter={props.parameter}
            targetScript={props.targetScript}
            setTargetScript={props.setTargetScript}
        />
    }

    if (props.targetScript.parameters[props.index].type === "file") {
        return <ToolParametersFileView
            index={props.index}
            parameter={props.parameter}
            targetScript={props.targetScript}
            setTargetScript={props.setTargetScript}
        />
    }

    if (props.targetScript.parameters[props.index].type === "editor") {
        return <ToolParametersEditorView
            index={props.index}
            parameter={props.parameter}
            targetScript={props.targetScript}
            setTargetScript={props.setTargetScript}
        />
    }

    return null;
};
