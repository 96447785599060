import { Container, FormField, Select } from "@cloudscape-design/components";
import * as React from "react";

export type ToolEnvironmentViewProps = {

    readonly region: string;
    readonly setRegion: (newRegion: string) => void;

    readonly stage: string;
    readonly setStage: (newStage: string) => void;
};

export const ToolEnvironmentView: React.FC<ToolEnvironmentViewProps> = (props: ToolEnvironmentViewProps) => {

    return <Container>
        <FormField
            label="Region"
        >
            <Select
                selectedOption={{
                    label: props.region,
                    value: props.region,
                }}
                onChange={({ detail }) => {
                    props.setRegion(detail.selectedOption.value!);
                }}
                options={["NA", "EU", "FE", "CN"].map((item) => {
                    return {
                        label: item,
                        value: item,
                    };
                })}
                selectedAriaLabel="Selected"
            />
        </FormField>
        <FormField
            label="Stage"
        >
            <Select
                selectedOption={{
                    label: props.stage,
                    value: props.stage,
                }}
                onChange={({ detail }) => {
                    props.setStage(detail.selectedOption.value!);
                }}
                options={["Alpha", "Beta", "Gamma", "Prod"].map((item) => {
                    return {
                        label: item,
                        value: item,
                    };
                })}
                selectedAriaLabel="Selected"
            />
        </FormField>
    </Container>;
};
