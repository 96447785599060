import { Monaco } from "@monaco-editor/react";
import { Sandbox } from "@sudoo/marked";
import { SandboxController } from "../controller";

const moduleName = "module/widget";

export const mountWidgetModule = (sandbox: Sandbox) => {

    sandbox.provide(moduleName, {
        "renderList": (controller: SandboxController, items: any[]) => {

            controller.pushBlock({
                type: 'list',
                elements: items,
            });
        },
        "renderDiff": (controller: SandboxController, original: any, modified: any) => {

            controller.pushBlock({
                type: 'diff',
                original: typeof original !== "string" ? JSON.stringify(original, null, 2) : original,
                modified: typeof modified !== "string" ? JSON.stringify(modified, null, 2) : modified,
            })
        },
    });
};

export const mountWidgetMonaco = (monaco: Monaco) => {

    const source = [
        `declare module '${moduleName}' {`,
        `declare function renderList(items: Array<{text: string, href?: string}>);`,
        `declare function renderDiff(original: any, modified: any);`,
        `}`
    ].join('');

    monaco.languages.typescript.javascriptDefaults.addExtraLib(source, moduleName);
};
