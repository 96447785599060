import Axios from "axios";
import { API_GATEWAY_DOMAIN } from "./definition";
import { buildRequestHeaders } from "./util";

export type CallRecipePutInput = {

    readonly identifier?: string;
    readonly title: string;
    readonly script: string;
    readonly declaration: string;
};

export type CallRecipePutOutput = {

    readonly identifier: string;
    readonly replaced: boolean;
};

export const callRecipePut = async (
    input: CallRecipePutInput,
): Promise<CallRecipePutOutput> => {

    const url = `https://${API_GATEWAY_DOMAIN}/recipe`;

    const requestHeaders = await buildRequestHeaders();

    const response = await Axios.put(url, input, {
        headers: requestHeaders,
    });

    return response.data;
};
