import { Container, Header } from "@cloudscape-design/components";
import * as React from "react";
import { RecipeCacheManager } from "../editor/recipe-cache/recipe-cache-manager";
import { RecipeObject } from "../storage/declare";

export type ToolRecipeCacheViewProps = {
};

export const ToolRecipeCacheView: React.FC<ToolRecipeCacheViewProps> = (props: ToolRecipeCacheViewProps) => {

    const cachedRecipes: RecipeObject[] = RecipeCacheManager.getInstance().getCachedRecipes();

    return (<Container
        header={<Header
            variant="h3"
        >
            Recipe Loaded
        </Header>}
    >
        {cachedRecipes.length > 0 ? cachedRecipes.map((item: RecipeObject) => {
            return (<div key={item.identifier}>
                <div><strong>{item.owner}/{item.name}</strong></div>
                <div>{item.identifier}</div>
            </div>)
        }) : "No Recipe Cached"}
    </Container>);
};
