import { AppLayout } from '@cloudscape-design/components';
import { Monaco } from "@monaco-editor/react";
import * as React from "react";
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ContentView } from './content/content';
import { CurrentOpeningScriptContext } from './context/current-opening-script';
import { NavigationView } from './navigation/navigation';
import { callInventoryGet } from './proxy/inventory-get';
import { InventoryObject, ScriptObject } from './storage/declare';
import { ToolView } from './tool/tool';

export const LayoutView: React.FC = () => {

    const [toolOpen, setToolOpen] = useState(true);

    const location = useLocation();

    const editorRef = useRef<any | null>(null)
    const monacoRef = useRef<Monaco | null>(null);

    const [inventory, setInventory] = React.useState<null | InventoryObject>(null);
    const [currentOpeningScript, setCurrentOpeningScript] = React.useState<ScriptObject | null>(null);

    const updateInventoryMethod = async () => {
        const inventory: InventoryObject = await callInventoryGet();
        setInventory(inventory);
    };

    React.useEffect(() => {

        updateInventoryMethod();
    }, []);

    if (!/^\/script\/.+$/.test(location.pathname)) {
        return (<CurrentOpeningScriptContext.Provider
            value={{
                script: currentOpeningScript,
                setScript: setCurrentOpeningScript,
            }}
        >
            <AppLayout
                navigation={<NavigationView
                    inventory={inventory}
                    setInventory={setInventory}
                />}
                content={<ContentView
                    editorRef={editorRef}
                    monacoRef={monacoRef}
                    updateInventory={updateInventoryMethod}
                />}
                disableContentPaddings={true}
                toolsHide={true}
            />
        </CurrentOpeningScriptContext.Provider>);
    }

    return (<CurrentOpeningScriptContext.Provider
        value={{
            script: currentOpeningScript,
            setScript: setCurrentOpeningScript,
        }}
    >
        <AppLayout
            navigation={<NavigationView
                inventory={inventory}
                setInventory={setInventory}
            />}
            content={<ContentView
                editorRef={editorRef}
                monacoRef={monacoRef}
                updateInventory={updateInventoryMethod}
            />}
            disableContentPaddings={true}
            tools={<ToolView
                editorRef={editorRef}
                monacoRef={monacoRef}
            />}
            toolsOpen={toolOpen}
            onToolsChange={(value) => {
                setToolOpen(value.detail.open);
            }}
        />
    </CurrentOpeningScriptContext.Provider>);
};
