import { Container, Header, TextContent } from "@cloudscape-design/components";
import * as React from "react";
import { ResultLogBlockLine } from "../declare";

export type LogResultSectionViewProps = {

    readonly lines: ResultLogBlockLine[];
};

export const LogResultSectionView: React.FC<LogResultSectionViewProps> = (props: LogResultSectionViewProps) => {

    return <Container
        header={<Header
            variant="h3"
        >
            Log
        </Header>}
    >
        {props.lines.map((line, index: number) => {
            return <TextContent key={index}>
                <small>{new Date(line.timestamp).toLocaleString()}:</small>
                &nbsp;
                <span>{line.text}</span>
            </TextContent>
        })}
    </Container>
};
