import { Container, Header } from "@cloudscape-design/components";
import { DiffEditor, DiffOnMount, Monaco } from "@monaco-editor/react";
import * as React from "react";
import { useMemo } from "react";
import { mountEditorConfigs } from "../../editor/monaco";

export type DiffResultSectionViewProps = {

    readonly original: string;
    readonly modified: string;
};

export const DiffResultSectionView: React.FC<DiffResultSectionViewProps> = (props: DiffResultSectionViewProps) => {

    const handleEditorDidMount: DiffOnMount = (editor: any, monaco: Monaco) => {

        mountEditorConfigs(monaco);
    };

    const originalLines: string[] = props.original.split('\n');
    const modifiedLines: string[] = props.modified.split('\n');
    const smallEditorSize = Math.min(Math.max(originalLines.length * 20, modifiedLines.length * 20), 240);

    return <Container
        header={<Header
            variant="h3"
        >
            Diff
        </Header>}
    >
        {useMemo(() => {
            return <DiffEditor
                height={smallEditorSize}
                options={{
                    lineNumbers: "off",
                    readOnly: true,
                    wordWrap: "on",
                    minimap: {
                        enabled: false,
                    },
                }}
                original={props.original}
                originalLanguage="javascript"
                modified={props.modified}
                modifiedLanguage="javascript"
                onMount={handleEditorDidMount}
            />
        }, [])}
    </Container>
};
