import Axios from "axios";
import { API_GATEWAY_DOMAIN } from "./definition";
import { buildRequestHeaders } from "./util";
import { InventoryObject, ScriptObject } from "../storage/declare";

export const callScriptGet = async (identifier: string): Promise<ScriptObject> => {

    const url = `https://${API_GATEWAY_DOMAIN}/script`;

    const requestHeaders = await buildRequestHeaders();

    const response = await Axios.get(url, {
        params: {
            identifier,
        },
        headers: requestHeaders,
    });

    const responseScript = response.data.script;

    return {
        identifier: responseScript.identifier,
        name: responseScript.title,
        code: responseScript.script,
        parameters: JSON.parse(responseScript.parameters),
    };
};
