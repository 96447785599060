import { Monaco } from "@monaco-editor/react";
import * as React from "react";
import { MutableRefObject } from "react";
import { Route, Routes } from "react-router-dom";
import { RecipeObject, ScriptObject } from "../storage/declare";
import { EditorWrapperRecipeView } from "./editor-wrapper-recipe";
import { EditorWrapperScriptView } from "./editor-wrapper-script";
import { HeaderView } from "./header";
import { NewRecipeView } from "./new-recipe";
import { NewScriptView } from "./new-script";

export type ContentViewProps = {

    readonly editorRef: MutableRefObject<any | null>;
    readonly monacoRef: MutableRefObject<Monaco | null>;

    readonly updateInventory: () => void;
};

export const ContentView: React.FC<ContentViewProps> = (props: ContentViewProps) => {

    return <Routes>
        <Route
            path="/"
            element={<HeaderView />}
        />
        <Route
            path="/new-script"
            element={<NewScriptView
                updateInventory={props.updateInventory}
            />}
        />
        <Route
            path="/new-recipe"
            element={<NewRecipeView
                updateInventory={props.updateInventory}
            />}
        />
        <Route
            path="/script/*"
            element={<EditorWrapperScriptView
                editorRef={props.editorRef}
                monacoRef={props.monacoRef}
            />}
        />
        <Route
            path="/recipe/*"
            element={<EditorWrapperRecipeView
                editorRef={props.editorRef}
                monacoRef={props.monacoRef}
            />}
        />
    </Routes>
};
