import { Monaco } from "@monaco-editor/react";
import { MarkedAnalyzer, New_Line_Character } from "@sudoo/marked";
import { RecipeCacheManager } from "../recipe-cache/recipe-cache-manager";

export const editorResolveRecipesModifier = async (
    code: string,
    currentDecorations: string[],
    editor: any,
    monaco: Monaco,
): Promise<string[]> => {

    try {

        const analyzer = await MarkedAnalyzer.fromSourceAsync(code, "typescript");

        const nodes = analyzer.findAllNodes("ImportDeclaration");

        const recipeManager = RecipeCacheManager.getInstance();

        const deltaDecorations: any[] = [];

        for (const node of nodes) {

            const source: string = String(node.source.value);
            const range: any = new monaco.Range(
                node.loc!.start.line,
                node.loc!.start.column,
                node.loc!.end.line,
                node.loc!.end.column,
            );

            if (!source.startsWith("recipe")) {
                deltaDecorations.push({
                    range,
                    options: {
                        hoverMessage: {
                            value: [
                                `Source: AwesomePlatform Built-In`,
                            ].join(New_Line_Character),
                        },
                        afterContentClassName: 'inlineDecorationImportBuiltIn',
                    },
                });
                continue;
            }

            const splitWithSlash: string[] = source.slice(7).split("/");

            if (splitWithSlash.length === 1) {

                const tapped = recipeManager.tapRecipeCheckedByIdentifier(splitWithSlash[0]);
                if (tapped !== null) {
                    deltaDecorations.push({
                        range,
                        options: {
                            hoverMessage: {
                                value: [
                                    `Source: AwesomePlatform Recipe  `,
                                    `Owner: **${tapped.owner}**  `,
                                    `Title: **${tapped.name}**  `,
                                    `Identifier: **${tapped.identifier}**`,
                                ].join(New_Line_Character),
                            },
                            afterContentClassName: 'inlineDecorationImportCached',
                        },
                    });
                    continue;
                }

                recipeManager.getRecipeByIdentifier(splitWithSlash[0]);
                deltaDecorations.push({
                    range,
                    options: {
                        hoverMessage: {
                            value: [
                                `Source: AwesomePlatform Recipe  `,
                                `**Loading...**`,
                            ].join(New_Line_Character),
                        },
                        afterContentClassName: 'inlineDecorationImportLoading',
                    },
                });
            } else {

                const tapped = recipeManager.tapRecipeCachedByOwner(splitWithSlash[0], splitWithSlash[1]);
                if (tapped !== null) {
                    deltaDecorations.push({
                        range,
                        options: {
                            hoverMessage: {
                                value: [
                                    `Source: AwesomePlatform Recipe  `,
                                    `Owner: **${tapped.owner}**  `,
                                    `Title: **${tapped.name}**  `,
                                    `Identifier: **${tapped.identifier}**`,
                                ].join(New_Line_Character),
                            },
                            afterContentClassName: 'inlineDecorationImportCached',
                        },
                    });
                    continue;
                }

                await recipeManager.getRecipeByOwner(splitWithSlash[0], splitWithSlash[1]);
                deltaDecorations.push({
                    range,
                    options: {
                        hoverMessage: {
                            value: [
                                `Source: AwesomePlatform Recipe  `,
                                `**Loading...**`,
                            ].join(New_Line_Character),
                        },
                        afterContentClassName: 'inlineDecorationImportLoading',
                    },
                });
            }
        }

        const updatedCurrentDecorations = editor.deltaDecorations(currentDecorations, deltaDecorations);

        return updatedCurrentDecorations;
    } catch (error) {
        return currentDecorations;
    }
};
