import { getMidwayJwtToken } from "../auth/midway-jwt-token";

export const buildRequestHeaders = async () => {

    const requestToken = await getMidwayJwtToken();

    return {
        authorization: `bearer ${requestToken}`,
    };
};
