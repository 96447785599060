import { Button, Container, Header, SpaceBetween } from "@cloudscape-design/components";
import Editor, { Monaco, OnMount } from "@monaco-editor/react";
import { MarkedDebugFlowController, MarkedDebugSnapshot } from "@sudoo/marked";
import * as React from "react";
import { mountEditorConfigs } from "../../editor/monaco";
import { ScriptObject } from "../../storage/declare";
import { DebugResultSectionScopeView } from "./debug-scope";

export type DebugResultSectionViewProps = {

    readonly targetScript: ScriptObject;
    readonly snapshot: MarkedDebugSnapshot;
    readonly flowController: MarkedDebugFlowController;
};

export const DebugResultSectionView: React.FC<DebugResultSectionViewProps> = (props: DebugResultSectionViewProps) => {

    const handleEditorDidMount: OnMount = (editor: any, monaco: Monaco) => {

        mountEditorConfigs(monaco);
    };

    const clip: string = props.snapshot.sliceCodeClip();
    const lines: string[] = clip.split('\n');
    const smallEditorSize = Math.min(lines.length * 20, 120);

    return <Container
        header={<Header
            variant="h3"
            actions={
                <SpaceBetween
                    direction="horizontal"
                    size="xs"
                >
                    <Button
                        variant="primary"
                        onClick={() => {
                            props.flowController.nextStep();
                        }}>
                        Next Step
                    </Button>
                    <Button
                        onClick={() => {
                            props.flowController.continue();
                        }}>
                        Continue
                    </Button>
                    <Button
                        onClick={() => {
                            props.flowController.terminate();
                        }}>
                        Terminate
                    </Button>
                </SpaceBetween>
            }
        >
            Debug
        </Header>}
        footer={<Editor
            height={smallEditorSize}
            defaultLanguage="javascript"
            options={{
                lineNumbers: "off",
                readOnly: true,
                wordWrap: "on",
                minimap: {
                    enabled: false,
                },
            }}
            value={clip}
            onMount={handleEditorDidMount}
        />}
    >
        <DebugResultSectionScopeView
            scope={props.snapshot.scope}
        />
    </Container>
};
