import { ResultBlock } from "../result/declare";

export const appendResultBlock = (previous: ResultBlock[], target: ResultBlock): ResultBlock[] => {

    if (previous.length === 0) {
        return [target];
    }

    const lastBlock = previous[previous.length - 1];
    if (lastBlock.type === 'log' && target.type === 'log') {

        return [
            ...previous.slice(0, previous.length - 1),
            {
                type: 'log',
                value: [
                    ...lastBlock.value,
                    ...target.value,
                ],
            },
        ];
    }

    if (lastBlock.type === 'debug') {

        if (target.type === 'debug') {

            return [
                ...previous.slice(0, previous.length - 1),
                target,
            ];
        } else {

            return appendResultBlock(
                previous.slice(0, previous.length - 1),
                target,
            );
        }
    }

    return [
        ...previous,
        target,
    ];
};
