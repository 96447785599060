import Axios from "axios";
import { API_GATEWAY_DOMAIN } from "./definition";
import { buildRequestHeaders } from "./util";
import { InventoryObject } from "../storage/declare";

export const callInventoryGet = async (): Promise<InventoryObject> => {

    const url = `https://${API_GATEWAY_DOMAIN}/inventory`;

    const requestHeaders = await buildRequestHeaders();

    const response = await Axios.get(url, {
        params: {
            includeScripts: true,
            includeRecipes: true,
        },
        headers: requestHeaders,
    });

    return {
        scripts: response.data.scripts.map((script: any) => {
            return {
                identifier: script.identifier,
                name: script.title,
            };
        }),
        recipes: response.data.recipes.map((recipe: any) => {
            return {
                identifier: recipe.identifier,
                name: recipe.title,
            };
        }),
    };
};
