import { SideNavigation, SideNavigationProps, Spinner } from "@cloudscape-design/components";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { InventoryObject } from "../storage/declare";

export type NavigationViewProps = {

    readonly inventory: InventoryObject | null;
    readonly setInventory: (newInventory: InventoryObject) => void;
};

export const NavigationView: React.FC<NavigationViewProps> = (props: NavigationViewProps) => {

    const location = useLocation();

    if (props.inventory === null) {

        return <Spinner />
    }

    const scriptItems: SideNavigationProps.Item[] = props.inventory.scripts.map((each): SideNavigationProps.Item => {

        return {
            type: 'link',
            text: each.name,
            href: `#/script/${each.identifier}`,
        };
    });

    const recipeItems: SideNavigationProps.Item[] = props.inventory.recipes.map((each): SideNavigationProps.Item => {

        return {
            type: 'link',
            text: each.name,
            href: `#/recipe/${each.identifier}`,
        };
    });

    return <SideNavigation
        activeHref={`#${location.pathname}`}
        header={{ text: "AWESOME Platform", href: "#" }}
        items={[
            {
                type: "section",
                text: 'My Recipes',
                items: recipeItems,
            },
            {
                type: "section",
                text: 'My Scripts',
                items: scriptItems,
            },
            { type: "divider" },
            {
                type: "link",
                text: "New Recipe",
                href: "#/new-recipe",
            },
            {
                type: "link",
                text: "New Script",
                href: "#/new-script",
            },
            { type: "divider" },
            {
                type: "link",
                text: "Documentation",
                href: "https://w.amazon.com/bin/view/User/mengwcp/AWESOMEPlatform/",
                external: true
            }
        ]}
    />;
};
