import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app';
import { getMidwayJwtToken } from './auth/midway-jwt-token';
import "./styles/editor.scss";

(async () => {
  // Make sure Midway is present before rendering
  await getMidwayJwtToken();

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
})();
