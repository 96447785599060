import { Container, Header } from "@cloudscape-design/components";
import { END_SIGNAL, MarkedResult } from "@sudoo/marked";
import * as React from "react";

export type FinalResultSectionViewProps = {

    readonly result: MarkedResult;
};

export const FinalResultSectionView: React.FC<FinalResultSectionViewProps> = (props: FinalResultSectionViewProps) => {

    if (props.result.signal !== END_SIGNAL.SUCCEED) {
        return <Container>
            Failed
        </Container>;
    }

    const namedExport: Record<string, any> = props.result.exports.named;

    return <Container
        header={<Header
            variant="h3"
        >
            Final Result
        </Header>}
    >
        {Object.keys(namedExport).map((key: string, index: number) => {
            return <div key={index}>
                {[
                    key,
                    JSON.stringify(namedExport[key]),
                ].join(": ")}
            </div>
        })}
        Default: {JSON.stringify(props.result.exports.default)}
    </Container>
};
